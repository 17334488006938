<template>
  <v-dialog
    v-model="isOpen"
    max-width="500"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        Изменить пароль
      </v-card-title>
      <v-card-text>
        <v-form ref="formPassForgot" v-model="valid" lazy-validation>
          <v-text-field
            label="Пароль"
            outlined
            :rules="passwordRules"
            v-model="password"
            :type="password_show ? 'text' : 'password'"
            :append-icon="password_show ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="password_show = !password_show"
            data-vv-name="password"
            required
            ref="password"
          ></v-text-field>
          <v-text-field
            :rules="passwordRules"
            label="Пароль повторно"
            outlined
            v-model="confirmPassword"
            :type="confirmPassword_show ? 'text' : 'password'"
            :append-icon="confirmPassword_show ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="confirmPassword_show = !confirmPassword_show"
            data-vv-name="confirmPassword"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" @click="save()">Сохранить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'ChangePasswordModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      valid: false,
      password: '',
      confirmPassword: '',
      password_show: false,
      confirmPassword_show: false,
    };
  },
  computed: {
    passwordRules() {
      return this.$store.getters.PASSWORD_RULES;
    },
    isLoading() {
      return this.$store.getters.PROFILE_IS_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_PASSWORD_CHANGE_MODAL_OPENED;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('CHANGE_PASSWORD_MODAL', false);
    },
    save() {
      if (this.$refs.formPassForgot.validate()) {
        if (this.password !== this.confirmPassword) {
          this.$toast.error("Пароли не совпадают");
          return
        }
        this.$store.dispatch('CHANGE_USER_PASSWORD', this.password)
          .then(() => {
            this.$toast.success("Пароль успешно изменен");
            this.$store.dispatch('CHANGE_PASSWORD_MODAL', false);
          })
          .catch(() => {
            this.$toast.error("Ошибка при изменении пароля");
          });
      } else {
        this.$toast.error("Заполните все обязательные поля");
      }
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    }
  },
};
</script>
